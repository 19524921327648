<template>
  <div-wrapper>
    <div-wrapper id="scan-overlay" className="modal-overlaybg open">
    </div-wrapper>
    <div-wrapper
      id="scanning-data"
      className="modal_pageview slideup modelslideup"
    >
      <Span className="close-btn" @handleClick="$emit('closeModal')">
        <close-icon />
      </Span>
      <slot></slot>
    </div-wrapper>
  </div-wrapper>
</template>
<script>
import DivWrapper from "@/components/form/DivWrapper";
import Span from "@/components/other/Span";
import CloseIcon from "@/components/svgIcons/CloseIcon";

export default {
  props: {},
  components: {
    DivWrapper,
    CloseIcon,
    Span
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_modal-box.scss";
</style>
